import React from "react";
import Insta from "../assets/icons/insta.png";
import TikTok from "../assets/icons/tiktok.png";
import Logo from "../assets/icons/logo.png"

export default function Navbar() {
    return (
        <div className="navbar">
            <div className="logo">
                <img src={Logo} alt="Logo WAY" />
            </div>
            <div className="cta-icons-navbar">
                <a href="https://www.instagram.com/waylemedia/" target='blank'>
                    <img src={Insta} alt="Instagram" />
                </a>
                <a href="https://www.tiktok.com/@waylemedia" target='blank'>
                    <img src={TikTok} alt="TikTok" />
                </a>
            </div>
        </div>
    );
}
