import BannerDesktop from "../assets/images/podcastbanner.webp";
import BannerMobile from "../assets/images/podcastbannermobile.webp";

const Podcast = (props) => {
    return (
        <div className="podcast">
            <h1 className="title">Notre podcast</h1>
            <a href="https://smartlink.ausha.co/metro-boulot-dodo" target="blank">
                {/* <img className="podcastbanner" src={Banner} /> */}
                <picture>
                    <source media="(min-width:600px)" srcSet={BannerDesktop} />
                    <img alt="Lien vers notre podcast" className="podcastbanner" src={BannerMobile} />
                </picture>
            </a>
        </div>
    );
};

export default Podcast;
