import "./App.css";
import React from "react";
import Head from "./components/head";
import Collaborators from "./components/collaborators";
import Concepts from "./components/concepts";
import Podcast from "./components/podcast";
import Collab from "./components/collab";
import Contact from "./components/contact";
import Footer from "./components/footer";


function App() {
    return (
        <div className="App">
            <Head />
            <Collaborators />
            <Concepts />
            <Podcast />
            <Collab />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
