import React from "react";
import { useState } from "react";
import { ConceptLeftComponent, ConceptRightComponent } from "./utils/concept";

import FirstDate from "../assets/videos/firstdate.mp4";
import Tacotac from "../assets/videos/tacotac.mp4";
import Touttracé from "../assets/videos/touttracé.mp4";
import Films from "../assets/videos/films.mp4";
import Oneday from "../assets/videos/1jourdanslaviede.mp4";

import MiniaFirstDate from "../assets/images/minias/miniaFirstDate.png";
import MiniaTacotac from "../assets/images/minias/miniaTacotac.png";
import MiniaTouttracé from "../assets/images/minias/miniaTouttracé.png";
import MiniaFilms from "../assets/images/minias/miniaFilms.png";
import MiniaOneDay from "../assets/images/minias/miniaOneDay.png";

const Concepts = (props) => {
    const [seemore, setSeemore] = useState(false);

    function _seemore() {
        seemore === false ? setSeemore(true) : setSeemore(false);
        console.log(seemore);
    }

    return (
        <div className="concepts">
            <h1 className="title">Nos concepts phares</h1>
            <ConceptLeftComponent
                title="1ER DATE"
                color="#FF817D"
                description={
                    <p>
                        Quand tu rencontres une nouvelle personne, il y a
                        toujours cette question “Et du coup, tu fais quoi dans
                        la vie ?”. 1er Date c’est un format qui répond à cette
                        question, et à toutes celles qu’on n’ose pas forcément
                        poser sur la réalité d’un métier.
                    </p>
                }
                video={FirstDate}
                poster={MiniaFirstDate}
            />
            <ConceptRightComponent
                title="TACOTAC"
                color="#FF7701"
                description={
                    <p>
                        Y’a que des hommes dans le BTP ? Les recruteurs ne
                        lisent qu’un CV sur dix ? Les avocats peuvent sortir
                        d’affaires les pires coupables ? Tous les clichés qu’on
                        peut avoir et entendre sur une profession sont présentés
                        à quelqu’un qui l’exerce, pour qu’il nous raconte sa
                        vérité.
                    </p>
                }
                video={Tacotac}
                poster={MiniaTacotac}
            />
            <ConceptLeftComponent
                title="TOUT TRACÉ"
                color="#ECD267"
                description={
                    <p>
                        Parce que justement, notre vie n’est pas toute tracée
                        par avance, et qu’on fait tous des détours, des virages,
                        voire des demi-tour, et qu’il n’y a aucun mal à ça. Pour
                        se trouver, il faut commencer par se chercher. Nos
                        invités racontent leur parcours en le dessinant au
                        feutre sur notre tableau. (oui, on s’est inspiré des
                        Draw my life qu’on voyait sur Youtube en 2012.)
                    </p>
                }
                video={Touttracé}
                poster={MiniaTouttracé}
            />
            {seemore === false ? (
                <div className="button" onClick={_seemore}>
                    <p>VOIR PLUS</p>
                </div>
            ) : (
                <div></div>
            )}
            {seemore === true ? (
                <div>
                    <ConceptRightComponent
                        title="FILMS VS RÉALITÉ"
                        color="#6683AB"
                        description={
                            <p>
                                La pop culture fait beaucoup de mal à
                                l’orientation, pour une raison très simple : les
                                films et les séries montrent une version pimpée
                                de la réalité, qui nous fait fantasmer certains
                                métiers. On fait réagir des professionnels à des
                                extraits de séries bien clichés pour qu’ils nous
                                racontent comment ça se passe dans la vraie vie.
                            </p>
                        }
                        video={Films}
                        poster={MiniaFilms}
                    />
                    <ConceptLeftComponent
                        title="1 JOUR DANS LA VIE DE"
                        color="#48C4B5"
                        description={
                            <p>
                                Il paraît qu’une image vaut mille mots. Parfois,
                                le mieux reste encore de le montrer.{" "}
                                <a href="https://www.tiktok.com/@totheyoungme">
                                    @totheyoungme
                                </a>{" "}
                                et sa caméra filme une journée dans la vie d’un
                                professionnel pour montrer le quotidien de son
                                métier.
                            </p>
                        }
                        video={Oneday}
                        poster={MiniaOneDay}
                    />
                    <div className="button" onClick={_seemore}>
                        <p>VOIR MOINS</p>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default Concepts;
