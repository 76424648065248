import Navbar from "./navbar";
import LogoS from "../assets/icons/logo-small.png";

export default function Head() {
    let width = window.innerWidth;
    return (
        <div>
            {width < 900 ? <Navbar /> : <></>}
            <div className="head-background">
                {width > 900 ? <Navbar /> : <></>}
                <div className="head">
                    <img className="logo-header" src={LogoS} alt="Logo Way" />
                    <h1>
                        Tu peux devenir qui tu veux,
                        <br />
                        tant que t’as les bonnes infos.
                    </h1>
                    <div className="text-head">
                        <p>
                            Le monde du travail évolue, les envies des nouvelles
                            générations aussi.
                            <br />
                            On produit des informations qui leur permettent de
                            s’y retrouver.
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className="big-buttons">
                <a
                    href="https://www.tiktok.com/@waylemedia"
                    target="blank"
                    className="button button-big"
                >
                    <div className="button-img button-big-img">
                        <img src={TikTok} alt="TikTok" />
                    </div>
                    <div className="button-text button-big-text">
                        <h2>74k</h2>
                        <h4>followers</h4>
                    </div>
                </a>
                <a
                    href="https://www.instagram.com/waylemedia/"
                    target="blank"
                    className="button button-big"
                >
                    <div className="button-img button-big-img">
                        <img src={Insta} alt="Instagram" />
                    </div>
                    <div className="button-text button-big-text">
                        <h2>3.2k</h2>
                        <h4>followers</h4>
                    </div>
                </a>
            </div> */}
        </div>
    );
}
