import React from "react";

import Insta from "../assets/icons/insta.png";
import TikTok from "../assets/icons/tiktok.png";

export default function Footer() {
    return (
        <div className="footer">
            <h2>WAY MÉDIA</h2>
            <div className="cta-icons-footer">
                <a href="https://www.instagram.com/waylemedia/" target="blank">
                    <img src={Insta} alt="Instagram" />
                </a>
                <a href="https://www.tiktok.com/@waylemedia" target="blank">
                    <img src={TikTok} alt="TikTok" />
                </a>
            </div>
        </div>
    );
}
