import Camembert from "../assets/images/camembert.png";

export default function Collab() {
    return (
        <div className="collab">
            <h1 className="title">Pourquoi vous devriez travailler avec nous</h1>
            <div className="camembert">
                <div className="camembert-legende">
                    <div className="legende">
                        <div
                            className="legende-color"
                            style={{ backgroundColor: "#6FCFBB" }}
                        ></div>
                        <p>On sait comment parler à la nouvelle génération</p>
                    </div>
                    <div className="legende">
                        <div
                            className="legende-color"
                            style={{ backgroundColor: "#FFE88D" }}
                        ></div>
                        <p>On a + de 400K abonnés</p>
                    </div>
                    <div className="legende">
                        <div
                            className="legende-color"
                            style={{ backgroundColor: "#9EC4E8" }}
                        ></div>
                        <p>
                            On produit aussi des concepts sur mesure, c’est pas
                            les idées qui manquent
                        </p>
                    </div>
                    <div className="legende">
                        <div
                            className="legende-color"
                            style={{ backgroundColor: "#C2A9FA" }}
                        ></div>
                        <p>On est vraiment très sympa</p>
                    </div>
                </div>
                <img alt="Camembert des raisons pour lesquelles collaborer avec nous" src={Camembert} />
            </div>
        </div>
    );
}
