import React from "react";

export class ConceptLeftComponent extends React.Component {
    render() {
        return (
            <div className="concept concept-left">
                <div className="minia">
                    <video
                        controls
                        poster={this.props.poster}
                        className="main-videos"
                    >
                        <source
                            src={this.props.video}
                            type="video/mp4"
                        ></source>
                    </video>
                </div>
                <div className="concept-left-text">
                    <ConceptName
                        title={this.props.title}
                        color={this.props.color}
                    />
                    {this.props.description}
                </div>
            </div>
        );
    }
}

export class ConceptRightComponent extends React.Component {
    render() {
        return (
            <div className="concept concept-right">
                <div className="concept-right-text">
                    <ConceptName
                        title={this.props.title}
                        color={this.props.color}
                    />
                    {this.props.description}
                </div>
                <div className="minia">
                    <video
                        controls
                        poster={this.props.poster}
                        className="main-videos"
                    >
                        <source
                            src={this.props.video}
                            type="video/mp4"
                        ></source>
                    </video>
                </div>
            </div>
        );
    }
}

export class ConceptName extends React.Component {
    render() {
        return (
            <div
                className="concept-name"
                style={{ backgroundColor: this.props.color }}
            >
                <h3>{this.props.title}</h3>
            </div>
        );
    }
}
