const Contact = (props) => {
    return (
        <>
            <h1 className="contact-title">Contactez-nous !</h1>
            <div className="contact">
                <div className="button">
                    <p
                        onClick={(e) => {
                            window.location.href =
                                "mailto:contact@waylemedia.fr";
                            e.preventDefault();
                        }}
                    >
                        contact@waylemedia.fr
                    </p>
                </div>
                <div className="button">
                    <p
                        onClick={(e) => {
                            window.location.href = "tel://+33757821021";
                            e.preventDefault();
                        }}
                    >
                        07 57 82 10 21
                    </p>
                </div>
            </div>
        </>
    );
};

export default Contact;
