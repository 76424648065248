import Logo1 from "../assets/images/logos/marine.png";
import Logo2 from "../assets/images/logos/talents.png";
import Logo3 from "../assets/images/logos/engie.png";
import Logo4 from "../assets/images/logos/lefigaro.png";
import Logo5 from "../assets/images/logos/sg.png";
import Logo6 from "../assets/images/logos/laposte.png";
import Logo7 from "../assets/images/logos/epide.png";
import Logo8 from "../assets/images/logos/studapart.png";

const Collaborators = (props) => {
    const logos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8];

    return (
        <div className="collaborators">
            <div className="scroll-start">
                {logos.map((item, i) => {
                    return <img className="collaborators-logos" src={item} alt="Collaborateurs"></img>;
                })}
            </div>
            <div className="scroll-end">
                {logos.map((item, i) => {
                    return <img className="collaborators-logos" src={item} alt="Collaborateurs"></img>;
                })}
            </div>
        </div>
    );
};

export default Collaborators;
